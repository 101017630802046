
import React, { useState, useEffect } from 'react';
import { CustomOverlayMap, Map, MapMarker, useKakaoLoader, ZoomControl } from "react-kakao-maps-sdk";
import styled from 'styled-components';

const CustomMap = styled(Map)`
  animation: zoomIn 1.5s forwards;

  @keyframes zoomIn {
    from {
      transform: scale(0.9);
    }
    to {
      transform: scale(1);
    }
  }
`

export default function MobileKakaoMap({ activeLocation, height }) {
  useKakaoLoader()

  // 각 지점별 위치 정보 정의
  const locations = {
    "본점": {
      lat: 37.524525,
      lng: 126.895115,
      title: "회계법인새시대(본점)",
      address: "서울특별시 영등포구 양산로 107"
    },
    "구로분실": {
      lat: 37.500997,
      lng: 126.874661,
      title: "회계법인새시대(구로분실)",
      address: "서울특별시 구로구 경인로53길 15"
    },
    "강남분실": {
      lat: 37.500624,
      lng: 127.042816,
      title: "회계법인새시대(강남분실)",
      address: "서울특별시 강남구 테헤란로38길 29"
    },
    "광주지점": {
      lat: 35.145893,
      lng: 126.8462422,
      title: "회계법인새시대(광주지점)",
      address: "광주광역시 서구 상무대로 759"
    }
  }

  const [state, setState] = useState({
    center: locations[activeLocation],
    isPanto: true,
  })

  // activeLocation이 변경될 때마다 center 위치 업데이트
  useEffect(() => {
    setState({
      center: locations[activeLocation],
      isPanto: true,
    })
  }, [activeLocation])

  return (
    <CustomMap
      center={state.center}
      isPanto={state.isPanto}
      style={{
        width: "100%",
        height: height,
      }}
      level={4}
      zoomable={false}
    >
      <MapMarker
        position={state.center}
      />
      <ZoomControl position={"RIGHT"} />
      <CustomOverlayMap
        position={state.center}
      >
        <div className="" style={{ top: "-80px", left: "-90px", position: "absolute" }}>
          <div className="info" style={{ backgroundColor: "#fff", height: "100%", boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)", padding: "7px 10px", textAlign: "center", borderRadius: "10px" }}>
            <div className="title" style={{ fontSize: "13px", fontWeight: "600", marginBottom: "5px" }}>
              {state.center.title}
            </div>
            <div className="body">
              <div className="desc">
                <div className="ellipsis" style={{ fontSize: "12px", color: "blue" }}>
                  {state.center.address}
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomOverlayMap>
    </CustomMap>
  );
}
