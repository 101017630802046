import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { scrolledState } from "../store/atom";
import { useRecoilValue } from "recoil";

export default function Header() {
  const [openSubmenu, setOpenSubmenu] = useState(false);
  const [submenuVisible, setSubmenuVisible] = useState(null);
  const isMainScrolled = useRecoilValue(scrolledState);
  const [isScrolled, setIsScrolled] = useState(false);

  const isMain = useLocation().pathname === "/";

  const menuItems = [
    {
      title: "회사소개",
      submenu: [
        { title: "대표이사 인사말", path: "/about?menu=대표이사 인사말" },
        { title: "연혁", path: "/about?menu=연혁" },
        { title: "조직도", path: "/about?menu=조직도" },
        { title: "오시는 길", path: "/about?menu=오시는 길" },
      ],
    },
    {
      title: "구성원",
      submenu: [
        { title: "전체보기", path: "/members?menu=전체보기" },
        { title: "본점", path: "/members?menu=본점" },
        { title: "구로분실", path: "/members?menu=구로분실" },
        { title: "강남분실", path: "/members?menu=강남분실" },
        { title: "광주지점", path: "/members?menu=광주지점" },
      ],
    },
    {
      title: "서비스",
      submenu: [
        { title: "회계감사", path: "/services?menu=회계감사" },
        { title: "세무자문", path: "/services?menu=세무자문" },
        { title: "재무자문", path: "/services?menu=재무자문" },
        { title: "경영자문", path: "/services?menu=경영자문" },
      ],
    },
    {
      title: "상담문의",
      submenu: [{ title: "온라인 상담 신청", path: "/consulting" }],
    },
  ];

  const handleMouseEnter = (index) => {
    setSubmenuVisible(index);
    setOpenSubmenu(true);
  };

  const handleMouseLeave = () => {
    setSubmenuVisible(null);
    setOpenSubmenu(false);
  };

  const scrolled = isMainScrolled || isScrolled;

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <header
        className={`header ${openSubmenu ? "open" : ""} ${
          scrolled ? "scrolled" : ""
        } ${!isMain ? "main" : ""}`}
      >
        <div className={`header-bg ${openSubmenu ? "open" : ""}`}></div>
        <div className="header-wrap">
          <div className="header-box">
            <Link
              className={`logo ${openSubmenu ? "open" : ""} ${
                scrolled ? "scrolled" : ""
              } `}
              to="/"
            >
              <span>회계법인새시대</span>
            </Link>
            <nav>
              <ul className="main-menu" onMouseLeave={handleMouseLeave}>
                {menuItems.map((item, index) => (
                  <li key={index} onMouseEnter={() => handleMouseEnter(index)}>
                    <Link to={item.submenu[0].path}>{item.title}</Link>
                    <ul
                      className={`sub-menu ${
                        openSubmenu && submenuVisible === index ? "open" : ""
                      }`}
                    >
                      {item.submenu.map((subItem, subIndex) => (
                        <li key={subIndex}>
                          <Link to={subItem.path}>{subItem.title}</Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      </header>
    </>
  );
}
