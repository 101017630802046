import { ServiceContainer, ServiceContent, ServiceImageItem, ServiceImageWrapper, ServiceList, ServiceListContainer, ServiceListItem, ServiceListTitle } from "./MobileAccounting";
import MobileSubTitle from "./MobileSubTitle";
import ServiceImage from "../../assets/images/finance_bg.jpg";
import Circle from "../../assets/icons/circle.svg";


export function MobileFinance() {
  return (
    <ServiceContainer>
      <MobileSubTitle title="재무자문" />
      <ServiceContent>
        <p>회계법인 새시대는 장부기장 대행, M&A 자문, 가치평가, 기업지배구조 개편,
          구조조정 등에서 고객의 기업가치를 극대화 할 수 있는 최고의
          자문 서비스를 제공하고 합니다.
        </p>
      </ServiceContent>
      <ServiceListContainer>
        <ServiceListTitle>
          <img src={Circle} alt="" />
          <h5> 서비스 항목</h5>
        </ServiceListTitle>
        <ServiceList>
          <ServiceListItem><span>-</span>M&A(분할 포함)</ServiceListItem>
          <ServiceListItem><span>-</span>가치평가 업무</ServiceListItem>
        </ServiceList>
      </ServiceListContainer>
      <ServiceImageWrapper>
        <ServiceImageItem src={ServiceImage} alt="서비스 이미지" />
      </ServiceImageWrapper>
    </ServiceContainer>
  );
}
