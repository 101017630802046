import { useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import BackgroundImage from "../assets/images/visual_members.jpg";
import SubVisual from "../components/SubVisual";
import MemberListSection from "../components/MemberListSection";

const MainContent = styled.section`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  max-width: 1280px;

  @media (max-width: 1840px) {
    padding: 0 20px;
  }
`;

export function Members() {

  const [activeMenu, setActiveMenu] = useState("전체보기");

  const navigate = useNavigate();

  const menuList = useMemo(() => ["전체보기", "본점", "구로분실", "강남분실", "광주지점"], []);

  const [searchParams, setSearchParams] = useSearchParams();

  const handleMenuClick = (menu) => {
    setSearchParams({ menu });
    setActiveMenu(menu);
  }

  useEffect(() => {
    const menu = searchParams.get("menu");

    if (!menu || !menuList.includes(menu)) {
      navigate("/about?menu=전체보기", { replace: true });
    }

    if (menu && menuList.includes(menu)) {
      setActiveMenu(menu);
    }
  }, [searchParams, menuList, navigate]);


  return (
    <div>
      <SubVisual title="구성원" menuList={menuList} activeMenu={activeMenu} backgroundImage={BackgroundImage} setActiveMenu={handleMenuClick} />
      <MainContent>
        <MemberListSection activeMenu={activeMenu} />
      </MainContent>
    </div>
  );
}