import styled from "styled-components";

import { useRecoilState } from "recoil";
import NewVisual from "../components/NewVisual";
import LocationSection from "../components/LocationSection";
import ServicesSection from "../components/ServicesSection";
import { useRef, useState } from "react";
import FooterComponent from "../components/Footer";
import { currentSectionState } from "../store/atom";

const Container = styled.div`
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  position: fixed;
`;

const SectionsWrapper = styled.div`
  width: 100%;
  height: 100%;
  transition: transform 0.8s ease-in-out;
  transform: translateY(
    ${(props) =>
    props.currentSection === 3
      ? `calc(-200vh - 342px)`
      : `-${props.currentSection * 100}vh`}
  );
`;

export default function NewHome() {
  const sectionRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const [currentSectionIndex, setCurrentSectionIndex] =
    useRecoilState(currentSectionState);
  const isScrolling = useRef(false);

  const handleWheel = (e) => {
    if (isScrolling.current) return;
    isScrolling.current = true;

    // 스크롤 이벤트가 발생할 때마다 타이머를 초기화
    clearTimeout(isScrolling.timeout);
    isScrolling.timeout = setTimeout(() => {
      isScrolling.current = false;
    }, 1100);

    if (e.deltaY > 0) {
      scrollToNextSection();
    } else {
      scrollToPrevSection();
    }
  };

  const scrollToNextSection = () => {
    scrollToSection(currentSectionIndex + 1);
  };

  const scrollToPrevSection = () => {
    scrollToSection(currentSectionIndex - 1);
  };

  const scrollToSection = (index) => {
    if (index >= 0 && index < sectionRefs.length) {
      setCurrentSectionIndex(index);

      // 애니메이션을 해당 섹션이 활성화될 때 실행
      const target = sectionRefs[index].current;
      if (target) {
        target.classList.add("animate");
        setTimeout(() => {
          target.classList.remove("animate");
        }, 1000);
      }
    }
  };

  return (
    <>
      <Container onWheel={handleWheel}>
        <SectionsWrapper currentSection={currentSectionIndex}>
          <NewVisual ref={sectionRefs[0]} animate={currentSectionIndex === 0} />
          <ServicesSection
            ref={sectionRefs[1]}
            animate={currentSectionIndex === 1}
          />
          <LocationSection
            ref={sectionRefs[2]}
            animate={currentSectionIndex === 2}
          />
          <FooterComponent ref={sectionRefs[3]} />
        </SectionsWrapper>
      </Container>
    </>
  );
}
