import { forwardRef, useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, EffectFade, Autoplay, Mousewheel } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import newVisual01 from "../assets/images/new_visual-1.jpg"
import newVisual02 from "../assets/images/new_visual-2.jpg"
import newVisual03 from "../assets/images/new_visual-3.jpg"
import newVisual04 from "../assets/images/new_visual-4.jpg"
import prevIcon from '../assets/icons/prev.png';
import nextIcon from '../assets/icons/next.png';

const VisualSection = styled.section`
  width: 100%;
  position: relative;
  height: 100vh;
  margin: 0 auto;

  .swiper {
  width: 100%;
  height: 100%;
  position: relative;
  }

  .swiper-slide {
    background-position: center;
    background-size: cover;
  }

.swiper-pagination {
    z-index: 10;
  }
  .swiper-pagination-bullet {
    background-color: #f0f1f3;
  }
  .swiper-pagination-bullet-active {
    background-color: var(--primary-c);
  }
  .swiper-vertical > .swiper-pagination-bullets, .swiper-pagination-vertical.swiper-pagination-bullets {
    transform: translate3d(-30px, -50%, 0);

    @media (max-width: 980px) {
      transform: translate3d(-10px, -50%, 0);
    }
  }

`;

const VisualInnerSliderItem = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const SwiperImage = styled.img`
  width: 100%;
  height: 100%;
  z-index: -2;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`;

const Visual01Image = styled(SwiperImage)`
  background-image: url(${newVisual01});
`;

const Visual02Image = styled(SwiperImage)`
  background-image: url(${newVisual02});
`;

const Visual03Image = styled(SwiperImage)`
  background-image: url(${newVisual03});
`;

const Visual04Image = styled(SwiperImage)`
  background-image: url(${newVisual04});
`;

const VisualInnerSliderOverlay1 = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 20%;
  background: linear-gradient(to top, rgba(255, 255, 255, 0), black);
  opacity: 0.6;
`;

const VisualInnerSliderOverlay2 = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: -1;
`;

const VisualInnerContentContainer = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1600px;
  padding: 0 65px;
  margin: 0 auto;
  z-index: 100;

  @media (max-width: 1920px) {
    padding: 0 170px;
  }
  /* @media (max-width: 1280px) {
    padding: 0 60px;
  }
  @media (max-width: 980px) {
    padding: 0 40px;
  } */

`;

const VisualInnerContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  justify-content: center;
  gap: 40px 0;
  color: #fff;
`;

const VisualInnerTitle = styled.h2`
  letter-spacing: -0.015em;
  font-size: calc(var(--font-size)* 0.88);
  font-weight: 800;
  opacity: 0;
  animation: fadeInDown 1.5s forwards;

@keyframes fadeInDown {
from {
  opacity: 0;
  transform: translateY(-40px);
}
to {
  opacity: 1;
  transform: translateY(0);
}
}


  @media (max-width: 1840px) {
    font-size: calc(var(--font-size)* 0.74);
  }

  @media (max-width: 1280px) {
    font-size: calc(var(--font-size)* 0.64);
  }
`;

const VisualInnerText = styled.p`
  font-weight: 300;
  line-height: 1.5;
  font-size: 2rem;
  animation: fadeInDown 1.5s forwards;

@keyframes fadeInDown {
from {
  opacity: 0;
  transform: translateY(-40px);
}
to {
  opacity: 1;
  transform: translateY(0);
}
}

  @media (max-width: 1200px) {
    font-size: 1.8rem;
  }
`;

const PaginationButtonContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: 10;
`;

const PaginationButtonWrapper = styled.div`
  width: 100%;
  max-width: 1600px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1920px) {
    padding: 0 100px;
  }
  @media (max-width: 1280px) {
    padding: 0 60px;
  }
  /* @media (max-width: 980px) {
    padding: 0 40px;
  } */
  `;

const PaginationButton = styled.button`
  width: 50px;
  height: 50px;
  transition: all 0.3s;


  @media (max-width: 1950px) {
    &:last-child {
      /* margin-right: 5%; */
    }
  }

  @media (max-width: 1000px) {
    width: 45px;
    height: 45px;
  }

  >img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const MouseIconWrapper = styled.div`
  width: 40px;
  height: 60px;
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;

  // 아이콘 스타일
  border: 1px solid white; // 외곽선 색상
  border-radius: 20px; // 둥근 모서리
  background-color: transparent; // 배경색 투명
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: '';
    width: 10px; // 가운데 점 크기
    height: 10px; // 가운데 점 크기
    border-radius: 50%; // 둥글게
    background-color: white; // 점 색상
    animation: bounce 1.5s infinite; // 애니메이션 추가
    opacity: 1; // 초기 투명도
  }

  @keyframes bounce {
    0%, 100% {
      transform: translateY(-15px);
      opacity: 1; // 완전 보임
    }
    50% {
      transform: translateY(0); // 위로 튕기는 효과
      opacity: 0; // 사라짐
    }
  }
`;

const GlobalStyle = createGlobalStyle`
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const NewVisual = forwardRef((props, ref) => {
  const [swiperIndex, setSwiperIndex] = useState(0); // -> 페이지네이션용
  const [swiper, setSwiper] = useState(); // -> 슬라이드용

  const handlePrev = () => {
    swiper?.slidePrev()
  }
  const handleNext = () => {
    swiper?.slideNext()
  }

  const swiperSetting = {
    direction: 'vertical',
    speed: 1000,
    autoplay: { delay: 3200, disableOnInteraction: false },
    effect: 'fade',
    pagination: {
      clickable: true,
    },
    loop: true,
    modules: [EffectFade, Autoplay, Pagination, Mousewheel],
    className: "mySwiper",
    onActiveIndexChange: (e) => setSwiperIndex(e.realIndex),
    onSwiper: (e) => { setSwiper(e) }
  }
  return (
    <>
      <VisualSection ref={ref}>
        <Swiper {...swiperSetting}>
          <SwiperSlide>
            <VisualInnerSliderItem>
              <Visual01Image src={newVisual01} alt="" />
              <VisualInnerContentContainer>
                <VisualInnerContent>
                  <VisualInnerTitle>
                    Trusted Accounting Partner
                  </VisualInnerTitle>
                  <VisualInnerText>
                    신뢰할 수 있는 회계 파트너로서, 정확한 재무 관리와 투명한 보고를 제공합니다.
                  </VisualInnerText>
                </VisualInnerContent>
              </VisualInnerContentContainer>
              <VisualInnerSliderOverlay1 />
            </VisualInnerSliderItem>
          </SwiperSlide>
          <SwiperSlide>
            <VisualInnerSliderItem>
              <Visual02Image src={newVisual02} alt="" />
              <VisualInnerContentContainer>
                <VisualInnerContent>
                  <VisualInnerTitle>
                    Your Financial Ally
                  </VisualInnerTitle>
                  <VisualInnerText>
                    고객의 재정 파트너로서, 목표 달성을 위해 함께합니다.
                  </VisualInnerText>
                </VisualInnerContent>
              </VisualInnerContentContainer>
              <VisualInnerSliderOverlay2 />
            </VisualInnerSliderItem>
          </SwiperSlide>
          <SwiperSlide>
            <VisualInnerSliderItem>
              <Visual03Image src={newVisual03} alt="" />
              <VisualInnerContentContainer>
                <VisualInnerContent>
                  <VisualInnerTitle>
                    Clear Financial Solutions
                  </VisualInnerTitle>
                  <VisualInnerText>
                    명확하고 신뢰할 수 있는 재무 솔루션을 통해  고객의 성공을 지원합니다.
                  </VisualInnerText>
                </VisualInnerContent>
              </VisualInnerContentContainer>
              <VisualInnerSliderOverlay1 />
            </VisualInnerSliderItem>
          </SwiperSlide>
          <SwiperSlide>
            <VisualInnerSliderItem>
              <VisualInnerContentContainer>
                <VisualInnerContent>
                  <VisualInnerTitle>
                    Precision in Finance
                  </VisualInnerTitle>
                  <VisualInnerText>
                    재무 관리에서의 정확성을 중시하며,  최상의 회계 솔루션을 제공합니다.
                  </VisualInnerText>
                </VisualInnerContent>
              </VisualInnerContentContainer>
              <Visual04Image src={newVisual04} alt="" />
              <VisualInnerSliderOverlay2 />
            </VisualInnerSliderItem>
          </SwiperSlide>
          <PaginationButtonContainer>
            <PaginationButtonWrapper>
              <PaginationButton onClick={handlePrev}>
                <img src={prevIcon} alt="이전" />
              </PaginationButton>
              <PaginationButton onClick={handleNext}>
                <img src={nextIcon} alt="다음" />
              </PaginationButton>
            </PaginationButtonWrapper>
          </PaginationButtonContainer>
        </Swiper>
        <MouseIconWrapper />
      </VisualSection>
    </>
  )
});

export default NewVisual;