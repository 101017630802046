import styled from "styled-components";

import MobileSubTitle from "./MobileSubTitle";
import Circle from "../../assets/icons/circle.svg";
import ServiceImage from "../../assets/images/accounting_bg.jpg";

export const ServiceContainer = styled.div`
  padding: 60px 20px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 80px;
  font-size: 1.6rem;
`;

export const ServiceContent = styled.div`
  line-height: 1.4;
  margin-bottom: 20px;

  p {
    line-height: 1.8;
    animation: fadeInDown 1.5s forwards;

  @keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translateY(-30px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  }
`;

export const ServiceListContainer = styled.div`
display: flex;
flex-direction: column;
gap: 15px;
animation: fadeInDown 1.5s forwards;

  @keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translateY(-30px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

export const ServiceListTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  
> img{
  width: 10px;
  height: 10px;
}

> h5 {
  font-size: 2.1rem;
}
`;

export const ServiceList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-left: 15px;
 
`;

export const ServiceListItem = styled.li`
>span{
  margin-right: 10px;
}
`;

export const ServiceImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
`;

export const ServiceImageItem = styled.img`
 width: 100%;
 height: 100%;

 animation: fadeInRight 1.5s forwards;

  @keyframes fadeInRight {
    from {
      opacity: 0;
      transform: translateX(30px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
`;

export function MobileAccounting() {
  return (
    <ServiceContainer>
      <MobileSubTitle title="회계감사" />
      <ServiceContent>
        <p>거래는 복잡하고 이해관계는 다변화하고 있으며 이에 따라 회계환경은
          급변하고 있습니다. 재무제표에 신뢰를 부여하고 그 가치를 제고하는 데
          기여하는 회계감사는 매우 중요합니다.
          회계법인 새시대는 열린 의사소통과  풍부한 감사경험 그리고 품질 최우선 정책을 기반으로 이 중요한
          회계감사를 제대로 잘 할 수 있는 회계법인입니다.
          <br />
          <br />
          회계관련 자문업무는 복잡하고 해석의 다양성이 존재하는 경우나 인력의 부족으로
          인해 회계관련 전문가가 필요한 경우에 회계관련 전문적 자문을
          제공하는 업무입니다.  </p>
      </ServiceContent>
      <ServiceListContainer>
        <ServiceListTitle>
          <img src={Circle} alt="원 아이콘" />
          <h5> 서비스 항목</h5>
        </ServiceListTitle>
        <ServiceList>
          <ServiceListItem><span>-</span>법정감사</ServiceListItem>
          <ServiceListItem><span>-</span>임의감사</ServiceListItem>
          <ServiceListItem><span>-</span>내부통제감사 업무대행</ServiceListItem>
        </ServiceList>
      </ServiceListContainer>
      <ServiceImageWrapper>
        <ServiceImageItem src={ServiceImage} alt="회계감사 이미지" />
      </ServiceImageWrapper>
    </ServiceContainer>
  );
}
