import SubVisual from "../components/SubVisual";
import { useEffect, useMemo, useState } from "react";
import Greeting from "../components/Greeting";
import History from "../components/History";
import Organization from "../components/Organization";
import Directions from "../components/Directions";
import { useNavigate, useSearchParams } from "react-router-dom";
import BackgroundImage from "../assets/images/visual_about.jpg";
import styled from "styled-components";

const MainContent = styled.section`
  padding-top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1280px;

  @media (max-width: 1840px) {
    padding: 0 20px;
  }
`;

export default function About() {

  const [activeMenu, setActiveMenu] = useState("대표이사 인사말");

  const menuList = useMemo(() => ["대표이사 인사말", "연혁", "조직도", "오시는 길"], []);

  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const handleMenuClick = (menu) => {
    setSearchParams({ menu });
    setActiveMenu(menu);
  }

  useEffect(() => {
    const menu = searchParams.get("menu");

    if (!menu || !menuList.includes(menu)) {
      navigate("/about?menu=대표이사 인사말", { replace: true });
    }

    if (menu && menuList.includes(menu)) {
      setActiveMenu(menu);
    }
  }, [searchParams, menuList, navigate]);

  return (
    <div>
      <SubVisual title="회사소개" menuList={menuList} activeMenu={activeMenu} backgroundImage={BackgroundImage} setActiveMenu={handleMenuClick} />
      <MainContent>
        {activeMenu === "대표이사 인사말" && <Greeting />}
        {activeMenu === "연혁" && <History />}
        {activeMenu === "조직도" && <Organization />}
        {activeMenu === "오시는 길" && <Directions />}
      </MainContent>
    </div>
  );
}

