import { ServiceContainer, ServiceContent, ServiceImageItem, ServiceImageWrapper, ServiceList, ServiceListContainer, ServiceListItem, ServiceListTitle } from "./MobileAccounting";
import MobileSubTitle from "./MobileSubTitle";
import ServiceImage from "../../assets/images/tax_bg.jpg";
import Circle from "../../assets/icons/circle.svg";



export function MobileTax() {
  return (
    <ServiceContainer >
      <MobileSubTitle title="세무자문" />
      <ServiceContent>
        <p>세금은 비즈니스에서 회피할 대상이 아니라 적극적으로 관리할 요소입니다.
          세법 규정과 프랙티스의 입체적인 이해, 적극적이고 창의적인 마인드,
          정확하고 체계적인 사실관계의 파악은 성공적인 조세 자문의 핵심입니다.</p>
      </ServiceContent>
      <ServiceListContainer>
        <ServiceListTitle>
          <img src={Circle} alt="" />
          <h5> 서비스 항목</h5>
        </ServiceListTitle>
        <ServiceList>
          <ServiceListItem><span>-</span>세무조사 업무 대행</ServiceListItem>
          <ServiceListItem><span>-</span>세무대리</ServiceListItem>
          <ServiceListItem><span>-</span>세무조정</ServiceListItem>
          <ServiceListItem><span>-</span>조세불복</ServiceListItem>
          <ServiceListItem><span>-</span>장부기장 대행</ServiceListItem>
        </ServiceList>
      </ServiceListContainer>
      <ServiceImageWrapper>
        <ServiceImageItem src={ServiceImage} alt="서비스 이미지" />
      </ServiceImageWrapper>
    </ServiceContainer >
  );
}
