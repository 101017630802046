import styled from "styled-components";
import OrganizationImage from "../assets/images/organization.png";
import SubTitle from "./SubTitle";

const OrganizationContainer = styled.div`
 width: 100%;
 height: 100%;
 padding-bottom: 140px;
`;

const OrganizationContent = styled.div`
  margin-top: 52px;
  width: 100%;
  height: 900px;
  animation: zoomIn 1.5s forwards;
  transition: height 0.4s ease-in-out;
  transition-delay: 0.4s;

@keyframes zoomIn {
    from {
      transform: scale(0.9);
    }
    to {
      transform: scale(1);
    }
  }

  @media (max-width: 1280px) {
    height: 700px;
  }
  @media (max-width: 1024px) {
    height: 500px;
  }
`;

const OrganizationImg = styled.div`
 background-image: url(${OrganizationImage});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
`


export default function Organization() {
  return (
    <OrganizationContainer>
      <SubTitle title="조직도" />
      <OrganizationContent>
        <OrganizationImg />
      </OrganizationContent>
    </OrganizationContainer>
  );
}