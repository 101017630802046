import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { getMembers } from "../api/members";
import SubTitle from "./SubTitle";

import { ReactComponent as SearchIcon } from "../assets/icons/search.svg";
import avatar from "../assets/images/avatar_3.png";

import MemberDetailModal from "./MemberDetailModal";
import { BASE_URL } from "../api/client";
import { useNavigate } from "react-router-dom";
import Skeleton from '@mui/material/Skeleton';


const MembersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding-bottom: 140px;
`;

const MembersContainerTitleBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const SearchBoxWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
  padding: 20px;
  animation: fadeInDown 1.5s forwards;

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-40px);
  }
  to {
    opacity: 1;
  transform: translateX(0);
}
}
`;

const SearchBox = styled.form`
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
 

  >input{
    width: 500px;
    height: 100%;
    border: 1px solid var(--border-c);
    padding-left: 25px;
    padding-right: 110px;
    background-color: #fff;
    font-size: 16px;
  }
`;

const SearchButton = styled.button` 
  width: 100px;
  height: 100%;
  background-color: var(--primary-c);
  padding: 10px 5px;

  >svg {
    width: 100%;
    height: 100%;
  }
`;

const MemberListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  animation: fadeInDown 1.5s forwards;

  @keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translateY(-40px);
    }
    to {
      opacity: 1;
    transform: translateX(0);
    }
  }

`;

const MemberItemWrapper = styled.div`
  width: 25%;
  margin-bottom: 50px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  padding: 0 20px;
  @media (max-width: 1080px) {
    width: 33%;
  }
`;

const MemberItemImageBox = styled.div`
  position: relative;
  max-height: 360px;
 `;

const MemberItemImageWrapper = styled.div`
  padding: 67.35% 0;
  
  
  img{
    -o-object-fit: cover;
    object-fit: cover;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); 
    width: 100%;
    height: 100%;
    max-width: 250px;
    overflow: hidden
  }
  `;

const MemberDetailButton = styled.button`
  position: absolute;
  bottom: -10px;
  right: 0;
  background-color: #bfbfbf;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  z-index: 10;
  padding: 25px 23px 24px 26px;
  transition: all 0.3s ease;

  @media  (max-width: 1280px) {
    width: 70px;
    height: 70px;
    padding: 20px 18px 19px 22px;
  }

  >svg {
    width: 100%;
    height: 100%;
  }
`;

const MemberInfoContainer = styled.div`
  width: 100%;
  margin-top: 10px;
`;

const MemberInfoItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  font-weight: 700;
  height: 100%;
  padding: 0 20px;
  `;

const MemberInfoItemRole = styled.p`
  font-size: 1.8rem;
  color: var(--primary-c);
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const MemberInfoItemName = styled.h4`
  font-size:2.4rem;
  flex-shrink: 0;
  text-align: right;
  padding-right: 10px;
`;
const MemberListSection = ({ activeMenu }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [members, setMembers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedMemberId, setSelectedMemberId] = useState(null);
  const [hasMemberList, setHasMemberList] = useState(false);

  const navigate = useNavigate();

  const fetchMembers = useCallback(async (name) => {
    setIsLoading(true);
    const data = await getMembers(activeMenu, name);
    const { members } = data;
    const serialMembers = members.map((member, index) => {
      const { createdAt, ...rest } = member;
      return {
        ...rest,
      };
    });
    setMembers(serialMembers);
    setHasMemberList(serialMembers.length > 0);
    setIsLoading(false);
  }, [setMembers, activeMenu]);

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const name = formData.get("name");
    if (name.trim() === "") {
      fetchMembers();
    } else {
      fetchMembers(name);
    }
  }

  useEffect(() => {
    fetchMembers();
  }, [fetchMembers, activeMenu, navigate]);

  return (
    <>

      <MembersContainer>
        <MembersContainerTitleBox>
          <SubTitle title={activeMenu} />
          <SearchBoxWrapper>
            <SearchBox onSubmit={handleSearchSubmit}>
              <input type="text" placeholder="이름 검색" name="name" />
              <SearchButton type="submit">
                <SearchIcon />
              </SearchButton>
            </SearchBox>
          </SearchBoxWrapper>
        </MembersContainerTitleBox>
        {!isLoading ? (
          hasMemberList && (
            <MemberListContainer>
              {members.map((member, index) => (
                <MemberItemWrapper
                  key={member.id}
                  no={index + 1}
                >
                  <MemberItemImageBox onClick={() => {
                    setSelectedMemberId(member.id);
                    setIsModalOpen(true);
                  }}>
                    <MemberItemImageWrapper>
                      {member.profile ? (
                        <img
                          src={`${BASE_URL}/image/${member.profile}`}
                          alt={member.name}
                        />
                      ) : (
                        <img src={avatar} alt={member.name} />
                      )}
                    </MemberItemImageWrapper>
                    <MemberDetailButton
                      onClick={() => {
                        setSelectedMemberId(member.id);
                        setIsModalOpen(true);
                      }}
                    >
                      <SearchIcon />
                    </MemberDetailButton>
                  </MemberItemImageBox>
                  <MemberInfoContainer>
                    <MemberInfoItem>
                      <MemberInfoItemRole>{member.role}</MemberInfoItemRole>
                      <MemberInfoItemName>{member.name}</MemberInfoItemName>
                    </MemberInfoItem>
                  </MemberInfoContainer>
                </MemberItemWrapper>
              ))}
            </MemberListContainer>
          )
        ) : (
          <MemberListContainer>
            {Array.from(new Array(4)).map((_, index) => (
              <Skeleton key={index} variant="rectangular" width={250} height={300} />
            ))}
          </MemberListContainer>
        )}
        {isModalOpen && (
          <MemberDetailModal
            onClose={() => {
              setIsModalOpen(false);
              setSelectedMemberId(null);
            }}
            memberId={selectedMemberId}
          />
        )}
      </MembersContainer>
    </>

  )
}

export default MemberListSection;